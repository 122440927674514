import { useState } from 'react'
import useSound from 'use-sound'
import beer from './assets/images/beer.png'
import duck from './assets/images/duck.png'
import egg from './assets/images/egg.png'
import quack from './assets/sounds/quack.mp3'
import cheer from './assets/sounds/cheer.mp3'
import { random } from "lodash-es"
import './App.css'

const App = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [result, setResult] = useState(false)
  const [count, setCount] = useState(random(2, 5, false))
  const [playQuack] = useSound(quack)
  const [playCheer] = useSound(cheer)

  const handlePlayButton = () => {
    setIsPlaying(true)
  }

  const handlePress = () => {
    if (count > 0) {
      setResult('duck')
      playQuack()
      setTimeout(() => setResult(null), 1000)
      setCount(count - 1)
    } else {
      setResult('drink')
      playCheer()
      setTimeout(() => setResult(null), 2000)
      setCount(random(2, 9, false))
    }
  }

  if (isPlaying) {
    if (result === 'duck') {
      return (
        <div className="App">
          <header className="App-header">
            <img src={duck} className="App-logo" alt="A duck." />
            <h2>Duck.</h2>
          </header>
        </div >
      )
    }

    if (result === 'drink') {
      return (
        <div className="App">
          <header className="App-header">
            <img src={beer} className="App-logo" alt="A beer glass filled with beer." />
            <h2>Drink!</h2>
          </header>
        </div >
      )
    }

    return (
      <div className="App">
        <header className="App-header" onClick={handlePress}>
          <img src={egg} className="App-logo" alt="An an egg." />
          <p className="App-instruction">Tap to play.</p>
        </header>
      </div >
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>DuckDuckDrink!</h1>
        <div class="App-logo-glow"></div>
        <img src={duck} className="App-logo" alt="A duck." />
        <button onClick={handlePlayButton}>Let's Play!</button>
      </header>
    </div>
  );
}

export default App
